import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

import LatestNewsArticle from '../components/LatestNewsArticle'
import DateTimeService from '../utils/common'

const LatestNewsArticleTemplate = ({ pageContext, location }) => {
  const article = pageContext.data
  const lang = 'ar'
  const articleUrl = `${process.env.GATSBY_BASE_URL}latest-news/${article.id}/`
  const datePublished =
    article.publishedAt || article.updatedAt || article.createdAt
  const formattedDatePublished =
    DateTimeService.formattedDateTime(datePublished)
  const formattedUpdatedDate = DateTimeService.formattedDateTime(
    article.updatedAt
  )

  return (
    <Layout
      maxWidth="auto"
      location={location}
      lang={lang}
      isArticle
      allowPopUp
      articleId={article.id}
    >
      <LatestNewsArticle article={article} lang={lang} />

      <Helmet>
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${articleUrl}"
              },
              "headline": "${article.title.replace(/\\?"/g, '\\"')}",
              "description": "${article.body
                .replace(/\\?"/g, '\\"')
                .replace(/\n/g, '')}",
              "author": {
                "@type": "Organization",
                "name": "Asharq News الشرق"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Asharq News الشرق",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://img.asharq.com/1401b346-d7f2-4b46-871f-b39fcf1b58a0.png?ixlib=js-2.3.1&s=5ea62a24e83b2792c87526e0be07d8ce"
                }
              },
              "datePublished": "${formattedDatePublished}",
              "dateModified": "${formattedUpdatedDate}"
            }`}
        </script>
      </Helmet>
    </Layout>
  )
}

export default LatestNewsArticleTemplate
